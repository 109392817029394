<template>
  <div class="container">
    <BaseH1 :text="'Twoje wykształcenie'" />

    <div class="wrapper-checkboxes">
      <InputCheckboxGroupSticked
        v-model="application.educationType"
        :name="'education-type'"
        :options="educationTypeOptions"
        :columns="1"
      />
      <TextHint
        :text="'Naciśnij dalej, jeśli żadna z opcji nie jest odpowiednia'"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputCheckboxGroupSticked from "@/components/UI/Base/InputCheckboxGroupSticked.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    InputCheckboxGroupSticked,
    TextHint,
  },

  props: {
    educationType: Array,
  },

  emits: ["update:educationType"],

  setup(props, { emit }) {
    const application = reactive({
      educationType: props.educationType,
    });

    watch(
      () => application.educationType,
      (newEducationType) => emit("update:educationType", newEducationType)
    );
    watch(
      () => props.educationType,
      (newEducationType) => (application.educationType = newEducationType)
    );

    const educationTypeOptions = [
      {
        label: "Artystyczne",
        value: "artistic",
      },
      {
        label: "Medyczne",
        value: "medical",
      },
      {
        label: "Muzyczne",
        value: "musical",
      },
      {
        label: "Pedagogiczne",
        value: "teaching",
      },
      {
        label: "Psychologiczne",
        value: "psychological",
      },
    ];

    return {
      application,
      educationTypeOptions,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.wrapper-checkboxes {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
